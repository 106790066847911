import kaboom from "kaboom";

kaboom({
  canvas: document.querySelector("#myCanvas"),
  background: [0, 0, 0],
});

const objs = ["sausage1", "sausage2", "whiteclaw"];

for (const obj of objs) {
  loadSprite(obj, `sprites/${obj}.png`);
}

loadSprite("bg", "sprites/bg_forest.png");
//loadSprite("ryan", "sprites/ryan.png");
loadSound("music", "music/raining_men.mp3");
loadSound("slurp", "sfx/slurp.mp3");

loadSpriteAtlas("/sprites/ryan_merged.png", {
  ryan: {
    x: 0,
    y: 0,
    width: 330,
    height: 256,
    sliceX: 2,
    anims: {
      idle: 0,
      eat: {
        from: 1,
        to: 0,
        speed: 10,
        loop: false,
      },
    },
  },
});

scene("start", () => {
  function addButton(txt, p, f) {
    const btn = add([
      text(txt),
      pos(p),
      area({ cursor: "pointer" }),
      scale(1),
      origin("center"),
    ]);

    btn.onClick(f);

    btn.onUpdate(() => {
      if (btn.isHovering()) {
        const t = time() * 10;
        btn.color = rgb(
          wave(0, 255, t),
          wave(0, 255, t + 2),
          wave(0, 255, t + 4)
        );
        btn.scale = vec2(1.2);
      } else {
        btn.scale = vec2(1);
        btn.color = rgb();
      }
    });
  }

  addButton("Start", vec2(width() / 2, height() / 2), () => go("main"));

  // reset cursor to default at frame start for easier cursor management
  onUpdate(() => cursor("default"));
});

scene("main", () => {
  add([sprite("bg", { width: width(), height: height() })]);

  play("music", {
    volume: 0.05,
    loop: true,
  });

  const PLAYER_SPEED = 1000;
  const SAUSAGE_SPEED = 120;
  const insaneMode = false;
  const booze = "whiteclaw";

  gravity(2400);

  const player = add([
    sprite("ryan", {anim: "idle"}),
    pos(width() / 2, height() - 120),
    scale(1),
    area({ height: 35, width: 100, offset: vec2(0, 80) }),
    origin("center"),
  ]);

  onKeyDown("left", () => {
    player.move(-PLAYER_SPEED, 0);
    //player.scale.x = -1;
    player.flipX(true);
    if (player.pos.x < 0) {
      player.pos.x = 0;
    }
  });

  onKeyDown("right", () => {
    player.move(PLAYER_SPEED, 0);
    //player.scale.x = 1;
    player.flipX(false);
    if (player.pos.x > width()) {
      player.pos.x = width();
    }
  });

  function spawnSausage() {
    const name = choose(objs.filter((n) => n != booze));
    add([
      sprite(name),
      rotate(choose([0, 180])),
      pos(rand(0, width()), -100),
      scale(0.75),
      area(),
      origin("center"),
      "sausage",
      // "enemy",
      { speed: rand(SAUSAGE_SPEED * 0.5, SAUSAGE_SPEED * 1.5) },
    ]);
    wait(1, spawnSausage);
  }

  player.onUpdate(() => {});

  player.onCollide("sausage", (s) => {
    destroy(s);
    player.play("eat")
    play("slurp", {
      volume: 1,
    });
  });

  onUpdate("sausage", (t) => {
    t.move(0, t.speed * (insaneMode ? 5 : 1));
    if (t.pos.y - t.height > height()) {
      destroy(t);
    }
  });

  onKeyPress("f", (c) => {
    burp();
  });

  spawnSausage();
});

go("start");
//debug.inspect = true
